@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,600,700,700i&subset=latin-ext);
html {
  font-family: sans-serif;
}

body {
  margin: 0;
}

article, aside, details, figcaption, figure, footer, header, main, menu, nav, section, summary {
  display: block;
}

audio, canvas, progress, video {
  vertical-align: baseline;
  display: inline-block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

[hidden], template {
  display: none;
}

a {
  background-color: rgba(0, 0, 0, 0);
}

a:active, a:hover {
  outline: 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b, strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

mark {
  color: #000;
  background: #ff0;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  clear: both;
  border: 0;
  border-top: 1px solid #ccc;
  height: 1px;
  margin: 1em 0;
  padding: 0;
  display: block;
}

pre {
  overflow: auto;
}

code, kbd, pre, samp {
  font-family: monospace;
  font-size: 1em;
}

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button {
  cursor: pointer;
}

html input[type="button"] {
  cursor: pointer;
}

input[type="reset"] {
  cursor: pointer;
}

input[type="submit"] {
  cursor: pointer;
}

button[disabled], html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

input[type="search"] {
  box-sizing: content-box;
}

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: .35em .625em .75em;
}

legend {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
}

optgroup {
  font-weight: bold;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td, th {
  padding: 0;
}

html {
  width: 100%;
  height: 100%;
}

body {
  color: #1d223c;
  overflow-anchor: none;
  background: #fff;
  min-height: 100%;
  font-family: Source Sans Pro, arial, sans-serif;
  font-size: 15px;
}

* {
  box-sizing: border-box;
}

a {
  color: #6d94a6;
  text-decoration: none;
}

a:hover {
  color: #6d94a6;
  text-decoration: underline;
}

img, input {
  max-width: 100%;
}

.smart-underline {
  text-shadow: 0 -2px #fff, 0 -1px #fff, 0 0 #fff, 2px -2px #fff, 2px -1px #fff, 2px 0 #fff, -2px -2px #fff, -2px -1px #fff, -2px 0 #fff, 1px -2px #fff, 1px -1px #fff, 1px 0 #fff, -1px -2px #fff, -1px -1px #fff, -1px 0 #fff, 0 -2px #fff, 0 -1px #fff, 0 0 #fff;
  text-decoration: none;
  transition: all .2s ease-in;
  box-shadow: inset 0 0 #fff, inset 0 -1px rgba(0, 0, 0, 0);
}

.smart-underline::selection {
  color: #fff;
  text-shadow: none;
  background: #7c9faf;
}

.smart-underline:hover {
  text-decoration: none;
  transition: all .2s ease-out;
  box-shadow: inset 0 0 #fff, inset 0 -1px #6d94a6;
}

.smart-underline-header {
  text-shadow: 0 -2px #fff, 0 -1px #fff, 0 0 #fff, 2px -2px #fff, 2px -1px #fff, 2px 0 #fff, -2px -2px #fff, -2px -1px #fff, -2px 0 #fff, 1px -2px #fff, 1px -1px #fff, 1px 0 #fff, -1px -2px #fff, -1px -1px #fff, -1px 0 #fff, 0 -2px #fff, 0 -1px #fff, 0 0 #fff;
  text-decoration: none;
  transition: all .2s ease-in;
  box-shadow: inset 0 0 #fff, inset 0 -2px rgba(0, 0, 0, 0);
}

.smart-underline-header::selection {
  color: #fff;
  text-shadow: none;
  background: #7c9faf;
}

.smart-underline-header:hover {
  text-decoration: none;
  transition: all .2s ease-out;
  box-shadow: inset 0 0 #fff, inset 0 -2px #6d94a6;
}

.small-top-padding {
  padding-top: 7px;
}

.branding-wrapper {
  background-color: #f9f9f9;
}

.wrapper {
  text-align: left;
  background-color: #fff;
  width: 1080px;
  margin: 0 auto;
  padding: 0 11px;
}

.wrapper:after, .wrapper:before {
  content: "";
  clear: both;
  display: table;
}

.wrapper.with-bg {
  background-color: #fff;
}

.inner-wrapper {
  clear: both;
  width: 1060px;
  position: relative;
}

.inner-wrapper:after, .inner-wrapper:before {
  content: "";
  clear: both;
  display: table;
}

.one-col, .two-col, .three-col, .four-col, .five-col, .six-col, .seven-col, .eight-col, .nine-col, .ten-col, .eleven-col, .twelve-col {
  clear: none;
  float: left;
  margin: 0 15px;
  padding: 0;
  display: inline;
  position: relative;
}

.one-col.no-margin, .two-col.no-margin, .three-col.no-margin, .four-col.no-margin, .five-col.no-margin, .six-col.no-margin, .seven-col.no-margin, .eight-col.no-margin, .nine-col.no-margin, .ten-col.no-margin, .eleven-col.no-margin, .twelve-col.no-margin {
  margin: 0;
}

.one-col {
  width: 58px;
}

.two-col {
  width: 146px;
}

.three-col {
  width: 234px;
}

.four-col {
  width: 322px;
}

.five-col {
  width: 410px;
}

.six-col {
  width: 498px;
}

.seven-col {
  width: 586px;
}

.eight-col {
  width: 674px;
}

.nine-col {
  width: 762px;
}

.ten-col {
  width: 850px;
}

.eleven-col {
  width: 938px;
}

.twelve-col {
  width: 1026px;
  margin-right: 0;
}

.prefix-one {
  margin-left: 88px;
}

.prefix-two {
  margin-left: 176px;
}

.prefix-three {
  margin-left: 264px;
}

.prefix-four {
  margin-left: 352px;
}

.prefix-five {
  margin-left: 440px;
}

.prefix-six {
  margin-left: 528px;
}

.prefix-seven {
  margin-left: 606px;
}

.prefix-eight {
  margin-left: 706px;
}

.prefix-nine {
  margin-left: 792px;
}

.prefix-ten {
  margin-left: 880px;
}

.prefix-eleven {
  margin-left: 918px;
}

.last-col {
  margin-right: 0;
}

.m-0 {
  margin: 0;
}

.mt-0 {
  margin-top: 0;
}

.mr-0 {
  margin-right: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.ml-0 {
  margin-left: 0;
}

.m-5 {
  margin: 5px;
}

.mt-5 {
  margin-top: 5px;
}

.mr-5 {
  margin-right: 5px;
}

.mb-5 {
  margin-bottom: 5px;
}

.ml-5 {
  margin-left: 5px;
}

.m-10 {
  margin: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.m-15 {
  margin: 15px;
}

.mt-15 {
  margin-top: 15px;
}

.mr-15 {
  margin-right: 15px;
}

.mb-15 {
  margin-bottom: 15px;
}

.ml-15 {
  margin-left: 15px;
}

.m-20 {
  margin: 20px;
}

.mt-20 {
  margin-top: 20px;
}

.mr-20 {
  margin-right: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.ml-20 {
  margin-left: 20px;
}

.m-25 {
  margin: 25px;
}

.mt-25 {
  margin-top: 25px;
}

.mr-25 {
  margin-right: 25px;
}

.mb-25 {
  margin-bottom: 25px;
}

.ml-25 {
  margin-left: 25px;
}

.m-30 {
  margin: 30px;
}

.mt-30 {
  margin-top: 30px;
}

.mr-30 {
  margin-right: 30px;
}

.mb-30 {
  margin-bottom: 30px;
}

.ml-30 {
  margin-left: 30px;
}

.m-35 {
  margin: 35px;
}

.mt-35 {
  margin-top: 35px;
}

.mr-35 {
  margin-right: 35px;
}

.mb-35 {
  margin-bottom: 35px;
}

.ml-35 {
  margin-left: 35px;
}

.m-40 {
  margin: 40px;
}

.mt-40 {
  margin-top: 40px;
}

.mr-40 {
  margin-right: 40px;
}

.mb-40 {
  margin-bottom: 40px;
}

.ml-40 {
  margin-left: 40px;
}

.m-45 {
  margin: 45px;
}

.mt-45 {
  margin-top: 45px;
}

.mr-45 {
  margin-right: 45px;
}

.mb-45 {
  margin-bottom: 45px;
}

.ml-45 {
  margin-left: 45px;
}

.m-50 {
  margin: 50px;
}

.mt-50 {
  margin-top: 50px;
}

.mr-50 {
  margin-right: 50px;
}

.mb-50 {
  margin-bottom: 50px;
}

.ml-50 {
  margin-left: 50px;
}

.m-55 {
  margin: 55px;
}

.mt-55 {
  margin-top: 55px;
}

.mr-55 {
  margin-right: 55px;
}

.mb-55 {
  margin-bottom: 55px;
}

.ml-55 {
  margin-left: 55px;
}

.m-60 {
  margin: 60px;
}

.mt-60 {
  margin-top: 60px;
}

.mr-60 {
  margin-right: 60px;
}

.mb-60 {
  margin-bottom: 60px;
}

.ml-60 {
  margin-left: 60px;
}

.m-65 {
  margin: 65px;
}

.mt-65 {
  margin-top: 65px;
}

.mr-65 {
  margin-right: 65px;
}

.mb-65 {
  margin-bottom: 65px;
}

.ml-65 {
  margin-left: 65px;
}

.m-70 {
  margin: 70px;
}

.mt-70 {
  margin-top: 70px;
}

.mr-70 {
  margin-right: 70px;
}

.mb-70 {
  margin-bottom: 70px;
}

.ml-70 {
  margin-left: 70px;
}

.m-75 {
  margin: 75px;
}

.mt-75 {
  margin-top: 75px;
}

.mr-75 {
  margin-right: 75px;
}

.mb-75 {
  margin-bottom: 75px;
}

.ml-75 {
  margin-left: 75px;
}

.m-80 {
  margin: 80px;
}

.mt-80 {
  margin-top: 80px;
}

.mr-80 {
  margin-right: 80px;
}

.mb-80 {
  margin-bottom: 80px;
}

.ml-80 {
  margin-left: 80px;
}

.m-85 {
  margin: 85px;
}

.mt-85 {
  margin-top: 85px;
}

.mr-85 {
  margin-right: 85px;
}

.mb-85 {
  margin-bottom: 85px;
}

.ml-85 {
  margin-left: 85px;
}

.m-90 {
  margin: 90px;
}

.mt-90 {
  margin-top: 90px;
}

.mr-90 {
  margin-right: 90px;
}

.mb-90 {
  margin-bottom: 90px;
}

.ml-90 {
  margin-left: 90px;
}

.m-95 {
  margin: 95px;
}

.mt-95 {
  margin-top: 95px;
}

.mr-95 {
  margin-right: 95px;
}

.mb-95 {
  margin-bottom: 95px;
}

.ml-95 {
  margin-left: 95px;
}

.m-100 {
  margin: 100px;
}

.mt-100 {
  margin-top: 100px;
}

.mr-100 {
  margin-right: 100px;
}

.mb-100 {
  margin-bottom: 100px;
}

.ml-100 {
  margin-left: 100px;
}

body {
  padding-top: 60px;
  font-family: Source Sans Pro, arial, sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 15px;
  font-family: Source Sans Pro, arial, sans-serif;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a, li a {
  transition: all .3s ease-in-out;
}

h1 {
  font-size: 34px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 26px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 21px;
}

h6 {
  font-size: 18px;
}

p {
  margin: 0 0 15px;
  font-size: 15px;
  line-height: 1.4;
}

p:last-of-type {
  margin: 0;
}

.h1-size {
  font-size: 34px;
}

.h2-size {
  font-size: 30px;
}

.h3-size {
  font-size: 26px;
}

.h4-size {
  font-size: 24px;
}

.h5-size {
  font-size: 21px;
}

.h6-size {
  font-size: 18px;
}

.table-default {
  width: 100%;
}

.table-default th, .table-default tr {
  text-align: left;
}

.table-default thead {
  border-bottom: 1px solid #b2d0dd;
}

.table-default thead tr {
  background-color: #fff;
}

.table-default tbody {
  border-bottom: 1px solid #b2d0dd;
}

.table-default tbody tr:nth-child(odd) {
  background-color: #fff;
}

.table-default tbody tr:nth-child(2n) {
  background-color: #b2d0dd;
}

.table-default td, .table-default th {
  padding: 10px;
}

.financial h1, .financial h2, .financial h3 {
  color: #6d94a6;
  font-size: 26px;
}

.financial h2 {
  padding: 15px 0 0;
}

.financial p {
  line-height: 1.7em;
}

.financial a {
  text-decoration: underline;
}

.financial a:hover {
  text-decoration: none;
}

.financial .issue-select {
  margin-bottom: 15px;
}

.financial .issue-select .issue-year {
  width: 150px;
  margin-right: 0;
}

.financial table {
  border-top: 5px solid #b2d0dd;
  width: 100%;
  margin-bottom: 15px;
}

.financial table a {
  color: #1d223c;
  text-decoration: underline;
}

.financial table a:hover {
  color: #6d94a6;
  text-decoration: none;
}

.financial table thead {
  border-bottom: 1px solid #b2d0dd;
}

.financial table thead tr th {
  box-sizing: border-box;
  padding: 18px 10px 17px;
}

.financial table thead tr th.padding-bottom-0 {
  padding-bottom: 0;
}

.financial table tbody tr td {
  padding: 10px;
}

.financial table tbody tr:first-child td {
  padding-top: 20px;
}

.basic-headline {
  color: #6d94a6;
  margin: 0;
  font-size: 26px;
  font-weight: bold;
}

body {
  color: #1d223c;
  background-color: #f9f9f9;
  min-width: 320px;
  margin: 0;
  padding: 0;
  font-family: Source Sans Pro, arial, sans-serif;
}

body.bg-color-dark {
  background-color: #1e1e1e;
}

footer {
  width: 1080px;
  margin: 0 auto;
}

.clear {
  clear: both;
}

.hidden, .hidden-xs {
  visibility: hidden;
  display: none;
}

.visible, .visible-xs {
  visibility: visible;
  display: block;
}

.delimiter {
  clear: both;
  height: 0;
  margin: -1px 0 0;
  overflow: hidden;
}

.cb {
  clear: both;
  height: 1px;
  margin-top: -1px;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.icon {
  display: inline-block;
}

.padding-bottom {
  padding-bottom: 15px;
}

.padding-top {
  padding-top: 15px;
}

.border-top {
  border-top: 1px solid #d3e6ef;
}

.border-bottom {
  border-bottom: 1px solid #d3e6ef;
}

.opacity0 {
  opacity: 0;
}

.opacity10 {
  opacity: .1;
}

.opacity30 {
  opacity: .3;
}

.opacity50 {
  opacity: .5;
}

.opacity100 {
  opacity: 1;
}

.page-header {
  height: 80px;
}

.page-header h1 {
  margin: 0;
  padding: 0;
  display: inline-block;
}

.page-header h1 > a {
  text-indent: -50px;
  color: rgba(0, 0, 0, 0);
  font-size: 1px;
  display: inline-block;
}

.page-header .dataimg-logo {
  float: left;
  width: 214px;
  height: 40px;
  display: inline-block;
}

.page-header .login {
  float: right;
  color: #6d94a6;
  text-align: center;
  margin-left: 40px;
  padding: 5px 0 0;
  font-size: 13px;
}

.page-header .login .dataimg-login {
  border: 0;
  width: 16px;
  height: 16px;
  display: inline-block;
}

.page-header .search {
  float: right;
  width: 290px;
}

.page-header .search button {
  float: left;
  background-color: #7200fe;
  border: 0;
  border-radius: 0 3px 3px 0;
  width: 40px;
  height: 40px;
  padding: 5px;
}

.page-header .search input[type="text"] {
  float: left;
  border: 1px solid #d3e6ef;
  border-right: 0;
  border-radius: 3px 0 0 3px;
  outline: none;
  width: 250px;
  height: 40px;
  padding-left: 15px;
  font-size: 13px;
}

.page-header .search input[type="text"]:focus {
  border: 1px solid #91c0d7;
  border-right: 0;
}

.page-header .search .dataimg-search {
  width: 20px;
  height: 20px;
  padding: 10px;
  display: inline-block;
}

.page-header:after {
  content: "";
  clear: both;
  height: 1px;
  margin-top: -1px;
  display: block;
}

.first .article-link {
  border-top: 1px solid #d3e6ef;
}

.title {
  margin: 10px 0 15px;
}

.title-box {
  color: #6d94a6;
  text-transform: uppercase;
  margin-top: 0;
  padding-top: 20px;
  font-size: 15px;
  font-weight: 600;
}

.category-desc {
  margin-bottom: 25px;
  font-weight: 600;
  line-height: 1.5em;
}

.version-switcher {
  text-align: center;
  margin: 30px auto;
}

.version-switcher .mobile, .version-switcher .desktop {
  margin: 0 25px;
  display: inline-block;
}

.version-switcher .mobile a, .version-switcher .desktop a {
  color: #6d94a6;
  font-size: 24px;
}

.version-switcher .mobile a:hover, .version-switcher .desktop a:hover {
  text-decoration: underline;
}

form.default-form:after, form.default-form:before {
  content: "";
  clear: both;
  display: table;
}

form.default-form input {
  box-sizing: border-box;
  max-width: 100%;
}

form.default-form input.input-text {
  color: #1d223c;
  border: 1px solid #b2d0dd;
  border-radius: 3px;
  outline: none;
  width: 100%;
  margin-top: 20px;
  padding: 10px;
  font-weight: 400;
  line-height: 18px;
  transition: all .3s ease-in-out;
  display: block;
}

form.default-form input[type="text"] {
  color: #1d223c;
  border: 1px solid #b2d0dd;
  border-radius: 3px;
  outline: none;
  width: 100%;
  margin-top: 20px;
  padding: 10px;
  font-weight: 400;
  line-height: 18px;
  transition: all .3s ease-in-out;
  display: block;
}

form.default-form input.input-text:disabled {
  color: #b2d0dd;
  background-color: #fff;
  border-color: #b6d2df;
}

form.default-form input[type="text"]:disabled {
  color: #b2d0dd;
  background-color: #fff;
  border-color: #b6d2df;
}

form.default-form input.input-text:hover:disabled {
  cursor: not-allowed;
}

form.default-form input[type="text"]:hover:disabled {
  cursor: not-allowed;
}

form.default-form input.input-text:hover:enabled {
  border-color: #b2d0dd;
}

form.default-form input[type="text"]:hover:enabled {
  border-color: #b2d0dd;
}

form.default-form input.input-text:focus, form.default-form input.input-text:hover:focus {
  border: 1px solid #7200fe;
}

form.default-form input[type="text"]:focus {
  border: 1px solid #7200fe;
}

form.default-form input[type="text"]:hover:focus {
  border: 1px solid #7200fe;
}

form.default-form input.submit-button {
  color: #fff;
  background-color: #6d94a6;
  border: 0;
  outline: none;
  width: 200px;
  margin-top: 20px;
  padding: 5px;
  font-weight: 700;
  display: block;
}

form.default-form textarea {
  box-sizing: border-box;
  resize: vertical;
  color: #1d223c;
  border: 1px solid #b2d0dd;
  border-radius: 3px;
  outline: none;
  width: 100%;
  max-width: 100%;
  min-height: 80px;
  margin-top: 20px;
  padding: 5px;
  transition: all .3s ease-in-out;
  display: block;
}

form.default-form textarea:disabled {
  color: #b2d0dd;
  background-color: #fff;
  border-color: #b6d2df;
}

form.default-form textarea:hover:disabled {
  cursor: not-allowed;
}

form.default-form textarea:hover:enabled {
  border-color: #b2d0dd;
}

form.default-form textarea:focus, form.default-form textarea:hover:focus {
  border: 1px solid #6d94a6;
}

form.default-form .success-message {
  float: left;
  color: #6d94a6;
  margin-left: 10px;
  display: none;
}

form.default-form .form-field {
  clear: both;
  margin-bottom: 20px;
}

form.default-form .form-field:after, form.default-form .form-field:before {
  content: "";
  clear: both;
  display: table;
}

form.default-form .form-field label {
  float: left;
  color: #1d223c;
  width: 100%;
  padding: 7px;
}

form.default-form .form-field input {
  float: left;
  margin-top: 0;
}

form.default-form .form-field input[type="checkbox"] {
  margin: 7px;
}

form.default-form .form-field .error-message {
  float: left;
  color: #6d94a6;
  margin-top: 5px;
  font-size: 18px;
  display: none;
}

form.default-form .form-field.with-error .error-message {
  display: block;
}

form.default-form .form-field.with-error input, form.default-form .form-field.with-error textarea, form.default-form .form-field.with-error input:hover, form.default-form .form-field.with-error textarea:hover {
  border: 1px solid #6d94a6;
}

form.default-form .form-field.with-error label {
  color: #6d94a6;
}

form.default-form .form-field .counter {
  color: #6d94a6;
  margin: 5px 0;
}

form.default-form .form-field-info {
  width: 100%;
}

form.default-form .form-field-info .dataimg-circle-info {
  float: right;
  width: 15px;
  height: 15px;
  position: relative;
}

form.default-form .form-field-info .dataimg-circle-info path {
  fill: #b2d0dd;
  transition: all .3s ease-in-out;
}

form.default-form .form-field-info .dataimg-circle-info:hover path {
  fill: #6d94a6;
}

form.default-form .form-field-info .dataimg-circle-info[data-tooltip]:hover:before {
  content: "";
  opacity: 1;
  border-top: 15px solid #fff;
  border-left: 10px solid rgba(0, 0, 0, 0);
  border-right: 10px solid rgba(0, 0, 0, 0);
  font-weight: 400;
  position: absolute;
  top: -15px;
  left: -5px;
}

form.default-form .form-field-info .dataimg-circle-info[data-tooltip]:hover:after {
  content: attr(data-tooltip);
  color: #1d223c;
  z-index: 20;
  background: #fff;
  border-radius: 3px;
  width: 120px;
  padding: 10px;
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  bottom: 30px;
  right: -20px;
}

form.default-form .form-group {
  width: auto;
  position: relative;
}

form.default-form .form-group input[type="text"] {
  padding-right: 60px;
}

form.default-form .form-group .currency-label {
  color: #1d223c;
  border-left: 2px solid #b2d0dd;
  width: 55px;
  margin: 10px 0;
  padding-left: 10px;
  position: absolute;
  right: 0;
}

form.default-form .form-group .icon-label {
  width: 20px;
  height: 20px;
  margin: 10px;
  position: absolute;
  right: 0;
}

form.default-form .form-inline-button .main-button {
  float: right;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 120px;
  margin: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 16px;
}

form.default-form .form-inline-button input[type="text"] {
  float: left;
  border-right: 0;
  border-radius: 3px 0 0 3px;
  width: calc(100% - 120px);
}

form.default-form .form-inline-button input[type="text"]:focus {
  border: 1px solid #6d94a6;
}

form.default-form .form-inline-button input[type="text"]:hover:focus {
  border: 1px solid #6d94a6;
}

form.default-form .form-inline-button.form-inline-button-short .main-button {
  width: 40px;
}

form.default-form .form-inline-button.form-inline-button-short .main-button .icon {
  width: 20px;
  height: 20px;
  display: inline-block;
}

form.default-form .form-inline-button.form-inline-button-short input[type="text"] {
  width: calc(100% - 40px);
}

form.default-form .form-short input[type="text"] {
  max-width: 90px;
}

form.default-form .form-code input[type="text"] {
  text-align: center;
  max-width: 30px;
  margin-right: 10px;
  padding-left: 5px;
  padding-right: 5px;
}

form.default-form [type="checkbox"] {
  display: none;
}

form.default-form [type="checkbox"] + label {
  cursor: pointer;
  padding-left: 30px;
  font-weight: 700;
  position: relative;
}

form.default-form [type="checkbox"] + label:before {
  content: "";
  background: #fff;
  border: 1px solid #b2d0dd;
  border-radius: 3px;
  width: 18px;
  height: 18px;
  transition: all .3s ease-in-out;
  position: absolute;
  left: 0;
}

form.default-form [type="checkbox"] + label:after {
  content: "✓";
  color: #6d94a6;
  opacity: 0;
  font-size: 16px;
  line-height: .8;
  transition: all .3s;
  position: absolute;
  top: 10px;
  left: 5px;
  transform: scale(0);
}

form.default-form [type="checkbox"] + label:hover:before {
  border-color: #b2d0dd;
}

form.default-form [type="checkbox"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}

form.default-form [type="checkbox"]:disabled + label {
  color: #b2d0dd;
  cursor: not-allowed;
}

form.default-form [type="checkbox"]:disabled + label:before {
  color: #b2d0dd;
  cursor: not-allowed;
  border-color: #b6d2df;
}

form.default-form [type="checkbox"]:disabled + label:after {
  color: #8aa9b8;
  cursor: not-allowed;
}

form.default-form input[type="radio"] {
  display: none;
}

form.default-form input[type="radio"] + label {
  cursor: pointer;
  padding-left: 30px;
  font-weight: 700;
  position: relative;
}

form.default-form input[type="radio"] + label:before {
  content: "";
  background-color: #fff;
  border: 1px solid #b2d0dd;
  border-radius: 18px;
  width: 18px;
  height: 18px;
  transition: all .3s;
  position: absolute;
  top: 6px;
  left: 0;
}

form.default-form input[type="radio"] + label:after {
  content: "";
  color: #6d94a6;
  background: #6d94a6;
  border-radius: 12px;
  width: 0;
  height: 0;
  font-size: 0;
  transition: all .3s;
  position: absolute;
  top: 16px;
  left: 10px;
}

form.default-form input[type="radio"] + label:hover:before {
  border-color: #b2d0dd;
}

form.default-form input[type="radio"]:checked + label:after {
  width: 11.5px;
  height: 11.5px;
  top: 10px;
  left: 4px;
}

form.default-form input[type="radio"]:disabled + label {
  color: #b2d0dd;
  cursor: not-allowed;
}

form.default-form input[type="radio"]:disabled + label:before {
  border-color: #b6d2df;
}

form.default-form input[type="radio"]:disabled + label:after {
  background-color: #8aa9b8;
}

@media (max-width: 800px) {
  form.default-form input.input-text {
    border-radius: 0;
  }

  form.default-form input[type="text"] {
    border-radius: 0;
  }

  form.default-form textarea, form.default-form .form-inline-button .main-button {
    border-radius: 0;
  }

  form.default-form .form-inline-button input[type="text"] {
    border-radius: 0;
  }
}

a.button-secondary, .button-secondary, a.button-main, .button-main, .main-button {
  text-align: center;
  font-size: 21px;
  font-weight: bolder;
  line-height: 15px;
  font-family: 700;
  border: 0;
  border-radius: 2px;
  width: 240px;
  padding: 13px 0 12px;
  text-decoration: none;
  transition: background-color .5s;
  display: block;
  position: relative;
}

.button-secondary:disabled, .button-main:disabled, .main-button:disabled {
  color: #6d94a6;
  background: #b2d0dd;
}

.button-secondary:focus, .button-main:focus, .main-button:focus {
  outline: 0;
}

.button-secondary > .icon, .button-main > .icon, .main-button > .icon {
  background-position: center;
  width: 100%;
  height: 15px;
  display: inline-block;
}

.icon.button-secondary, .icon.button-main, .icon.main-button {
  width: 40px;
}

a.button-main, .button-main, .main-button {
  color: #fff;
  background: #7200fe;
}

a.button-main:hover:enabled, .button-main:hover:enabled, .main-button:hover:enabled {
  color: #fff;
  background: #4300b2;
  text-decoration: none;
}

a.button-main.clicked:hover:enabled, .button-main.clicked:hover:enabled, .main-button.clicked:hover:enabled {
  background: #7200fe;
}

a.button-secondary, .button-secondary {
  color: #fff;
  background: #6d94a6;
}

a.button-secondary:hover:enabled, .button-secondary:hover:enabled {
  color: #fff;
  background: #1d223c;
  text-decoration: none;
}

.button-social {
  width: 78px;
  height: 26px;
  display: inline-block;
  position: relative;
}

.button-social:hover span {
  text-decoration: underline;
}

.button-social .button-social-icon {
  width: 20px;
  height: 15px;
  position: absolute;
  top: 0;
}

.button-social .button-social-icon div {
  height: 15px;
}

.button-social span {
  color: #fff;
  vertical-align: middle;
  padding-left: 33px;
  font-size: 12px;
  font-weight: bold;
  line-height: 26px;
  display: inline-block;
}

@media (max-width: 800px) {
  .button-social span {
    text-align: center;
    width: 100%;
    padding: 0 0 0 10px;
  }
}

.button-facebook {
  background-color: #4868ba;
}

.button-facebook .button-social-icon {
  top: 5px;
  left: 12px;
}

.button-facebook .button-social-icon svg {
  height: 15px;
}

.button-twitter {
  background-color: #5db3e6;
}

.button-twitter .button-social-icon {
  top: 5px;
  left: 7px;
}

.button-twitter .button-social-icon svg {
  height: 15px;
}

.sticky-wrapper {
  min-height: 600px;
  margin-bottom: 20px;
  position: relative;
}

.sticky-wrapper .sticky-content {
  min-width: 322px;
}

.sticky-wrapper .fixed {
  position: fixed;
  top: 10px;
}

.ad-skyscrapper {
  padding: 0 15px;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.owl-height {
  transition: height .5s ease-in-out;
}

.owl-carousel {
  tap-highlight-color: transparent;
  z-index: 1;
  width: 100%;
  display: none;
  position: relative;
}

.owl-carousel .animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
}

.owl-carousel .owl-stage:after {
  content: ".";
  clear: both;
  visibility: hidden;
  height: 0;
  line-height: 0;
  display: block;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-controls .owl-nav .owl-prev, .owl-carousel .owl-controls .owl-nav .owl-next, .owl-carousel .owl-controls .owl-dot {
  cursor: hand;
  -webkit-user-select: none;
  user-select: none;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel .owl-refresh .owl-item {
  display: none;
}

.owl-carousel .owl-item {
  float: left;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  user-select: none;
  min-height: 1px;
  position: relative;
}

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity .4s;
}

.owl-carousel .owl-item img {
  transform-style: preserve-3d;
  width: 100%;
  display: block;
}

.owl-carousel.owl-text-select-on .owl-item {
  -webkit-user-select: auto;
  user-select: auto;
}

.owl-carousel .owl-grab {
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

.owl-carousel .owl-video-wrapper {
  background: #000;
  height: 100%;
  position: relative;
}

.owl-carousel .owl-video-play-icon {
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background: url("owl.video.play.png") no-repeat;
  width: 80px;
  height: 80px;
  margin-top: -40px;
  margin-left: -40px;
  transition: scale .1s;
  position: absolute;
  top: 50%;
  left: 50%;
}

.owl-carousel .owl-video-play-icon:hover {
  transition: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn, .owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  transition: opacity .4s;
}

.owl-carousel .owl-video-frame {
  z-index: 1;
  position: relative;
}

.no-js .owl-carousel {
  display: block;
}

.gallery-main-container, .gallery-vertical-container {
  background-color: #000;
  width: 100%;
  height: 100%;
  position: relative;
}

.gallery-main-container:after, .gallery-main-container:before, .gallery-vertical-container:after, .gallery-vertical-container:before {
  content: "";
  clear: both;
  display: table;
}

.gallery-main-container .gallery, .gallery-vertical-container .gallery {
  width: 100%;
  height: 91%;
}

.gallery-main-container .gallery .icon-loading, .gallery-vertical-container .gallery .icon-loading {
  width: 35px;
  height: 35px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 4%;
  right: 4%;
}

.gallery-main-container .gallery .images-container, .gallery-vertical-container .gallery .images-container {
  width: 100%;
  height: 100%;
}

.gallery-main-container .gallery .images-container .owl-item, .gallery-vertical-container .gallery .images-container .owl-item {
  height: 100%;
}

.gallery-main-container .gallery .images-container .owl-item img, .gallery-vertical-container .gallery .images-container .owl-item img {
  width: auto;
  max-height: 100%;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 4%;
  right: 4%;
}

.gallery-main-container .gallery .images-container .owl-item.advertisement:after, .gallery-vertical-container .gallery .images-container .owl-item.advertisement:after {
  content: "REKLAMA";
  color: #000;
  text-align: center;
  opacity: 0;
  z-index: -1;
  background: rgba(255, 255, 255, .3);
  width: 130px;
  height: 37px;
  padding: 0;
  font-size: 28px;
  font-weight: 600;
  transition: opacity .2s;
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 65px);
}

.gallery-main-container .gallery .images-container .owl-item.active > .advertisement:after, .gallery-vertical-container .gallery .images-container .owl-item.active > .advertisement:after {
  opacity: 1;
}

.gallery-main-container .gallery .images-container .owl-stage-outer, .gallery-vertical-container .gallery .images-container .owl-stage-outer, .gallery-main-container .gallery .images-container .owl-stage-outer .owl-stage, .gallery-vertical-container .gallery .images-container .owl-stage-outer .owl-stage, .gallery-main-container .gallery .images-container .owl-stage-outer .owl-stage .owl-item, .gallery-vertical-container .gallery .images-container .owl-stage-outer .owl-stage .owl-item {
  height: 100%;
}

.gallery-main-container .gallery .btn, .gallery-vertical-container .gallery .btn {
  z-index: 10;
  cursor: pointer;
  background: rgba(0, 0, 0, .6);
  width: 9%;
  height: 91%;
  display: block;
  position: absolute;
}

.gallery-main-container .gallery .btn .icon-arrow, .gallery-vertical-container .gallery .btn .icon-arrow {
  width: 13px;
  height: 26px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 4%;
  right: 4%;
}

.gallery-main-container .gallery .next, .gallery-vertical-container .gallery .next {
  right: 0;
}

.gallery-main-container .gallery .next .image-count, .gallery-vertical-container .gallery .next .image-count {
  text-align: center;
  color: #fff;
  margin: auto;
  font-size: 26px;
  font-weight: 300;
  position: absolute;
  bottom: 5px;
  left: 4%;
  right: 4%;
}

.gallery-main-container .image-description, .gallery-vertical-container .image-description {
  color: #aaa;
  padding: 20px 0;
}

.gallery-main-container .image-description div, .gallery-vertical-container .image-description div {
  display: inline-block;
}

.gallery-main-container .image-description .description, .gallery-vertical-container .image-description .description {
  padding: 0 5px 0 0;
}

.gallery-main-container .image-description .author, .gallery-vertical-container .image-description .author {
  font-style: italic;
}

.gallery-container.vertical .gallery-vertical-container {
  height: auto;
}

.gallery-container.vertical .gallery-vertical-container img[data-src^="http"], .gallery-container.vertical .gallery-vertical-container img[data-src^="http"] + .image-description {
  display: none;
}

.gallery-container.vertical .gallery-vertical-container img {
  margin: 10px 0 0;
}

.gallery-container.vertical .gallery-vertical-container .load-button {
  letter-spacing: 1px;
  margin: 20px auto;
  padding: 10px 0;
}

.gallery-container.vertical .gallery-vertical-container .image-description {
  padding: 20px 10px;
}

.gallery-container.vertical .gallery-vertical-container .image-description .image-description {
  clear: both;
  width: 100%;
  padding: 15px 0;
}

.gallery-container.vertical .gallery-vertical-container .image-description .author {
  float: left;
  display: block;
}

.gallery-container.vertical .gallery-vertical-container .image-description .photo-count {
  float: right;
  color: #fff;
  font-weight: 700;
}

.gallery-container.vertical .gallery-vertical-container .gallery {
  height: auto;
}

.gallery-container.vertical .gallery-vertical-container .images-container {
  margin-top: 35px;
}

.owl-nav.disabled {
  display: none;
}

.gallery-with-video .gallery-item .video-item {
  width: 81%;
  margin: auto;
}

.picker {
  text-align: left;
  color: #000;
  z-index: 10000;
  -webkit-user-select: none;
  user-select: none;
  font-size: 16px;
  line-height: 1.2;
  position: absolute;
}

.picker__input {
  cursor: default;
}

.picker__input.picker__input--active {
  border-color: #0089ec;
}

.picker__holder {
  -webkit-overflow-scrolling: touch;
  width: 100%;
  overflow-y: auto;
}

.picker {
  width: 100%;
}

.picker__holder {
  box-sizing: border-box;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity= 0);
  -moz-opacity: 0;
  opacity: 0;
  background: #fff;
  border: 0 solid #aaa;
  border-width: 0 1px;
  border-radius: 0 0 5px 5px;
  min-width: 176px;
  max-width: 466px;
  max-height: 0;
  transition: transform .15s ease-out, opacity .15s ease-out, max-height 0s .15s, border-width 0s .15s;
  position: absolute;
  transform: translateY(-1em)perspective(600px)rotateX(10deg);
}

.picker__frame {
  padding: 1px;
}

.picker__wrap {
  margin: -1px;
}

.picker--opened .picker__holder {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity= 100);
  -moz-opacity: 1;
  opacity: 1;
  border-top-width: 1px;
  border-bottom-width: 1px;
  max-height: 25em;
  transition: transform .15s ease-out, opacity .15s ease-out, max-height, border-width;
  transform: translateY(0)perspective(600px)rotateX(0);
  box-shadow: 0 6px 18px 1px rgba(0, 0, 0, .12);
}

.picker__box {
  padding: 0 1em;
}

.picker__header {
  text-align: center;
  margin-top: .75em;
  position: relative;
}

.picker__month, .picker__year {
  margin-left: .25em;
  margin-right: .25em;
  font-weight: 500;
  display: inline-block;
}

.picker__year {
  color: #999;
  font-size: .8em;
  font-style: italic;
}

.picker__select--month, .picker__select--year {
  border: 1px solid #b7b7b7;
  height: 2em;
  margin-left: .25em;
  margin-right: .25em;
  padding: .5em;
}

@media (min-width: 24.5em) {
  .picker__select--month, .picker__select--year {
    margin-top: -.5em;
  }
}

.picker__select--month {
  width: 35%;
}

.picker__select--year {
  width: 22.5%;
}

.picker__select--month:focus, .picker__select--year:focus {
  border-color: #0089ec;
}

.picker__nav--prev, .picker__nav--next {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: .5em 1.25em;
  position: absolute;
  top: -.25em;
}

@media (min-width: 24.5em) {
  .picker__nav--prev, .picker__nav--next {
    top: -.33em;
  }
}

.picker__nav--prev {
  padding-right: 1.25em;
  left: -1em;
}

@media (min-width: 24.5em) {
  .picker__nav--prev {
    padding-right: 1.5em;
  }
}

.picker__nav--next {
  padding-left: 1.25em;
  right: -1em;
}

@media (min-width: 24.5em) {
  .picker__nav--next {
    padding-left: 1.5em;
  }
}

.picker__nav--prev:before, .picker__nav--next:before {
  content: " ";
  border-top: .5em solid rgba(0, 0, 0, 0);
  border-bottom: .5em solid rgba(0, 0, 0, 0);
  border-right: .75em solid #000;
  width: 0;
  height: 0;
  margin: 0 auto;
  display: block;
}

.picker__nav--next:before {
  border-left: .75em solid #000;
  border-right: 0;
}

.picker__nav--prev:hover, .picker__nav--next:hover {
  cursor: pointer;
  color: #000;
  background: #b1dcfb;
}

.picker__nav--disabled, .picker__nav--disabled:hover, .picker__nav--disabled:before, .picker__nav--disabled:before:hover {
  cursor: default;
  background: none;
  border-left-color: #f5f5f5;
  border-right-color: #f5f5f5;
}

.picker__table {
  text-align: center;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  font-size: inherit;
  width: 100%;
  margin-top: .75em;
  margin-bottom: .5em;
}

@media (min-height: 33.875em) {
  .picker__table {
    margin-bottom: .75em;
  }
}

.picker__table td {
  margin: 0;
  padding: 0;
}

.picker__weekday {
  color: #999;
  width: 14.2857%;
  padding-bottom: .25em;
  font-size: .75em;
  font-weight: 500;
}

@media (min-height: 33.875em) {
  .picker__weekday {
    padding-bottom: .5em;
  }
}

.picker__day {
  border: 1px solid rgba(0, 0, 0, 0);
  padding: .3125em 0;
  font-weight: 200;
}

.picker__day--today {
  position: relative;
}

.picker__day--today:before {
  content: " ";
  border-top: .5em solid #0059bc;
  border-left: .5em solid rgba(0, 0, 0, 0);
  width: 0;
  height: 0;
  position: absolute;
  top: 2px;
  right: 2px;
}

.picker__day--disabled:before {
  border-top-color: #aaa;
}

.picker__day--outfocus {
  color: #ddd;
}

.picker__day--infocus:hover, .picker__day--outfocus:hover {
  cursor: pointer;
  color: #000;
  background: #b1dcfb;
}

.picker__day--highlighted {
  border-color: #0089ec;
}

.picker__day--highlighted:hover, .picker--focused .picker__day--highlighted {
  cursor: pointer;
  color: #000;
  background: #b1dcfb;
}

.picker__day--selected, .picker__day--selected:hover, .picker--focused .picker__day--selected {
  color: #fff;
  background: #0089ec;
}

.picker__day--disabled, .picker__day--disabled:hover, .picker--focused .picker__day--disabled {
  color: #ddd;
  cursor: default;
  background: #f5f5f5;
  border-color: #f5f5f5;
}

.picker__day--highlighted.picker__day--disabled, .picker__day--highlighted.picker__day--disabled:hover {
  background: #bbb;
}

.picker__footer {
  text-align: center;
}

.picker__button--today, .picker__button--clear, .picker__button--close {
  vertical-align: bottom;
  background: #fff;
  border: 1px solid #fff;
  width: 33%;
  padding: .66em 0;
  font-size: .8em;
  font-weight: bold;
  display: inline-block;
}

.picker__button--today:hover, .picker__button--clear:hover, .picker__button--close:hover {
  cursor: pointer;
  color: #000;
  background: #b1dcfb;
  border-bottom-color: #b1dcfb;
}

.picker__button--today:focus, .picker__button--clear:focus, .picker__button--close:focus {
  background: #b1dcfb;
  border-color: #0089ec;
  outline: none;
}

.picker__button--today:before, .picker__button--clear:before, .picker__button--close:before {
  height: 0;
  display: inline-block;
  position: relative;
}

.picker__button--today:before, .picker__button--clear:before {
  content: " ";
  margin-right: .45em;
}

.picker__button--today:before {
  border-top: .66em solid #0059bc;
  border-left: .66em solid rgba(0, 0, 0, 0);
  width: 0;
  top: -.05em;
}

.picker__button--clear:before {
  border-top: 3px solid #e20;
  width: .66em;
  top: -.25em;
}

.picker__button--close:before {
  content: "×";
  vertical-align: top;
  color: #777;
  margin-right: .35em;
  font-size: 1.1em;
  top: -.1em;
}

.picker__button--today[disabled], .picker__button--today[disabled]:hover {
  color: #ddd;
  cursor: default;
  background: #f5f5f5;
  border-color: #f5f5f5;
}

.picker__button--today[disabled]:before {
  border-top-color: #aaa;
}

.picker__list {
  margin: 0;
  padding: .75em 0 4.2em;
  list-style: none;
}

.picker__list-item {
  background: #fff;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  margin-bottom: -1px;
  padding: .75em 1.25em;
  position: relative;
}

@media (min-height: 46.75em) {
  .picker__list-item {
    padding: .5em 1em;
  }
}

.picker__list-item:hover {
  cursor: pointer;
  color: #000;
  z-index: 10;
  background: #b1dcfb;
  border-color: #0089ec;
}

.picker__list-item--highlighted {
  z-index: 10;
  border-color: #0089ec;
}

.picker__list-item--highlighted:hover, .picker--focused .picker__list-item--highlighted {
  cursor: pointer;
  color: #000;
  background: #b1dcfb;
}

.picker__list-item--selected, .picker__list-item--selected:hover, .picker--focused .picker__list-item--selected {
  color: #fff;
  z-index: 10;
  background: #0089ec;
}

.picker__list-item--disabled, .picker__list-item--disabled:hover, .picker--focused .picker__list-item--disabled {
  color: #ddd;
  cursor: default;
  z-index: auto;
  background: #f5f5f5;
  border-color: #ddd;
}

.picker--time .picker__button--clear {
  text-align: center;
  text-transform: uppercase;
  color: #666;
  background: none;
  border: 0;
  width: 80%;
  margin: 1em auto 0;
  padding: 1em 1.25em;
  font-size: .67em;
  font-weight: 500;
  display: block;
}

.picker--time .picker__button--clear:hover, .picker--time .picker__button--clear:focus {
  color: #fff;
  cursor: pointer;
  background: #e20;
  border-color: #e20;
  outline: none;
}

.picker--time .picker__button--clear:before {
  color: #666;
  font-size: 1.25em;
  font-weight: bold;
  top: -.25em;
}

.picker--time .picker__button--clear:hover:before, .picker--time .picker__button--clear:focus:before {
  color: #fff;
  border-color: #fff;
}

.picker--time {
  min-width: 256px;
  max-width: 320px;
}

.picker--time .picker__holder {
  background: #f2f2f2;
}

@media (min-height: 40.125em) {
  .picker--time .picker__holder {
    font-size: .875em;
  }
}

.picker--time .picker__box {
  padding: 0;
  position: relative;
}

.image-popup {
  cursor: pointer;
  z-index: 9999;
  visibility: hidden;
  position: absolute;
}

.image-popup .close-popup {
  text-align: right;
  color: #fff;
  text-transform: uppercase;
  background: #1d223c;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-size: 18px;
  font-weight: bold;
  line-height: 32px;
  text-decoration: none;
}

.image-popup .close-popup .ico {
  vertical-align: middle;
  margin: 0 5px;
  padding-bottom: 5px;
  font-size: 40px;
  font-weight: 100;
  display: inline-block;
  transform: rotate(45deg);
}

.image-popup .description {
  color: #fff;
  background: #1d223c;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 10px;
  font-size: 21px;
}

.image-popup .description span {
  margin-top: 5px;
  font-size: 13px;
  display: inline-block;
}

.breadcrumbs {
  margin: 10px 0;
}

.breadcrumbs ol {
  margin: 0;
  padding: 0;
}

.breadcrumbs ol li {
  color: #6d94a6;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  list-style-type: none;
  display: inline-block;
}

.breadcrumbs ol li a {
  color: #6d94a6;
  font-weight: 400;
  text-decoration: none;
}

.breadcrumbs ol li a:hover {
  color: #7093a7;
  text-decoration: underline;
}

.breadcrumbs ol li:last-of-type a {
  font-weight: 400;
}

.breadcrumbs ol .dataimg-arrow-right {
  text-align: center;
  width: 15px;
  height: 11px;
  margin: 0;
  display: inline-block;
  position: relative;
}

.breadcrumbs ol .dataimg-arrow-right svg {
  width: 5px;
}

.breadcrumbs ol .dataimg-arrow-right path {
  fill: #6d94a6;
}

.error-404 {
  text-align: center;
  width: 500px;
  margin: 0 auto;
}

.error-404 .icon-404 {
  border-bottom: 1px solid #d3e6ef;
}

.error-404 .icon-404 .icon-sanitka {
  margin: 0 auto;
}

.error-404 div {
  margin: 25px 0;
  font-size: 21px;
  font-weight: 700;
}

.error-404 div strong {
  font-size: 30px;
}

.error-404 div a {
  color: #7200fe;
  font-size: 13px;
  text-decoration: underline;
}

.error-404 div a:hover {
  text-decoration: none;
}

header nav {
  z-index: 10;
  display: table;
}

header nav .nav-main {
  vertical-align: top;
  display: table-cell;
}

header nav .dropdown-menu {
  width: 100%;
}

header nav .dropdown-menu > ul {
  table-layout: fixed;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: table;
}

header nav .dropdown-menu > ul > li {
  text-align: center;
  color: #7200fe;
  border-bottom: 1px solid #d3e6ef;
  padding-bottom: 20px;
  font-size: 15px;
  font-weight: 700;
  display: table-cell;
  position: relative;
}

header nav .dropdown-menu > ul > li a {
  color: #7200fe;
  vertical-align: middle;
  border-right: 1px solid #d3e6ef;
  display: block;
}

header nav .dropdown-menu > ul > li a strong {
  vertical-align: middle;
  text-transform: uppercase;
  margin-bottom: 2px;
  line-height: 18px;
  display: inline-block;
}

header nav .dropdown-menu > ul > li a .icon {
  vertical-align: middle;
  background-position: center;
  width: 18px;
  height: 18px;
  display: inline-block;
}

header nav .dropdown-menu > ul > li a .prevention {
  vertical-align: middle;
  background-image: url("/images/moje-zdravi/dist/non-svg/chran-se.png");
  background-position: 50%;
  background-size: 90px;
  width: 90px;
  height: 25px;
}

header nav .dropdown-menu > ul > li a > span {
  color: #6d94a6;
  clear: both;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  display: inline-block;
}

header nav .dropdown-menu > ul > li a > span:empty {
  content: " ";
}

header nav .dropdown-menu > ul > li a:hover {
  text-decoration: none;
}

header nav .dropdown-menu > ul > li a:hover strong {
  text-decoration: underline;
}

header nav .nav-more {
  width: 45px;
}

header nav .nav-more > a {
  border-right: 0 !important;
}

header nav .nav-more > a .icon.iconph {
  width: 0;
}

header nav .nav-more > a .icon:last-of-type {
  float: right;
  margin-right: 5px;
}

header nav .nav-more > a > strong {
  float: right;
  margin-top: 1px;
}

header nav .nav-more > ul {
  visibility: hidden;
  z-index: 10000;
  opacity: 0;
  pointer-events: none;
  background-color: #fff;
  width: 200px;
  margin-top: 1px;
  padding: 0;
  transition: all .3s ease-in-out;
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  transform: translateY(-30px);
}

header nav .nav-more > ul > li {
  border-left: 1px solid #d3e6ef;
  border-right: 1px solid #d3e6ef;
  display: block;
}

header nav .nav-more > ul > li:last-of-type {
  border-bottom: 1px solid #d3e6ef;
}

header nav .nav-more > ul > li a {
  color: #6d94a6;
  text-align: left;
  border-bottom: 1px solid #d3e6ef;
  border-right: 0;
  margin: 0 20px;
  padding: 10px 0;
  font-size: 15px;
  line-height: 30px;
  text-decoration: none;
  display: block;
}

header nav .nav-more > ul > li a strong {
  text-transform: none;
}

header nav .nav-more > ul > li a:hover {
  color: #7200fe;
  text-decoration: underline;
}

header nav .nav-more > ul > li:last-child a {
  border-bottom: 0;
}

header nav .nav-more:before {
  content: "";
  width: 75px;
  height: 50px;
  display: none;
  position: absolute;
  bottom: -1px;
  left: -75px;
}

header nav .nav-more:hover:before {
  display: block;
}

header nav .nav-more:hover ul {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  transform: translateY(0);
}

.header-dark {
  z-index: 100;
  background-color: #1e1e1e;
  width: 100%;
  height: 60px;
  position: fixed;
  top: 0;
}

.header-dark:after, .header-dark:before {
  content: "";
  clear: both;
  display: table;
}

.header-dark .header-container {
  width: 1080px;
  margin: auto;
}

.header-dark .header-container a {
  text-decoration: none;
}

.header-dark .header-container a:hover {
  text-decoration: underline;
}

.header-dark .header-container .logo {
  float: left;
  background-position: center;
  width: 190px;
  height: 50px;
  margin: 5px 15px 0 0;
}

.header-dark .header-container .gallery-close {
  color: #fff;
  float: right;
  margin: 20px 0;
  font-size: 13px;
  font-weight: bold;
  display: block;
  position: relative;
}

.header-dark .header-container .gallery-close span {
  text-transform: uppercase;
  position: relative;
  top: 5px;
}

.header-dark .header-container .gallery-close .icon {
  float: right;
  width: 25px;
  height: 25px;
  margin: 0 0 0 10px;
}

.header-dark .header-container .gallery-close:hover {
  text-decoration: underline;
}

.header-dark .header-container .titles-container {
  float: left;
  padding: 8px 20px 0;
  font-weight: bold;
}

.header-dark .header-container .titles-container a {
  text-decoration: none;
}

.header-dark .header-container .titles-container a:hover {
  text-decoration: underline;
}

.header-dark .header-container .titles-container .title {
  color: #b2d0dd;
  float: left;
  text-transform: uppercase;
  margin: 3px 0;
  font-size: 13px;
}

.header-dark .header-container .titles-container .article-title {
  color: #fff;
  float: left;
  clear: both;
  margin-top: 2px;
  font-size: 15px;
}

.main-article h1.title {
  color: #1d223c;
  margin: 15px 0;
  line-height: 38px;
  display: block;
}

.main-article h1.title:after, .main-article h1.title:before {
  content: "";
  clear: both;
  display: table;
}

.main-article h2 {
  color: #1d223c;
  margin-bottom: 0;
  padding-left: 60px;
  font-size: 21px;
  font-weight: bold;
}

.main-article h3 {
  color: #1d223c;
  margin: 0;
  padding: 15px 0 0 60px;
  font-size: 18px;
  font-weight: bold;
}

.main-article .ads table {
  border-top: 0;
  margin-bottom: 0;
}

.main-article .image-container {
  padding-bottom: 15px;
}

.main-article .image-container .description {
  color: #6d94a6;
  text-align: left;
  width: 100%;
  padding-top: 15px;
  display: block;
}

.main-article ul {
  margin-top: 0;
  margin-left: 60px;
  padding: 0;
  list-style: none;
}

.main-article ul li {
  padding-left: 17px;
  font-size: 17px;
}

.main-article ul li:before {
  content: "•";
  color: #6d94a6;
  margin-left: -17px;
  font-size: 26px;
  font-weight: 600;
  line-height: 20px;
  position: absolute;
}

.main-article ul.main {
  margin: 0;
}

.main-article ul.main > li {
  padding: 0;
}

.main-article ul.main > li:before {
  display: none;
}

.main-article .listing-controls {
  text-align: center;
}

.main-article .listing-controls button {
  display: none;
}

.main-article .listing-controls button.icon, .main-article .listing-controls button.start {
  float: right;
  margin-left: 5px;
}

.main-article .silhouette-image div {
  width: 690px;
  height: 408px;
}

.main-article .content p {
  padding-left: 60px;
  line-height: 22px;
}

.main-article .content p iframe {
  margin: 15px auto 15px 0;
}

.main-article .content p a {
  color: #7200fe;
  text-decoration: underline;
}

.main-article .content p a:hover {
  text-decoration: none;
}

.main-article .content .twitter-tweet {
  margin-left: 60px;
}

.main-article .content h2 {
  padding: 15px 0 0 60px;
}

.main-article .content table p {
  padding-left: 0;
}

.main-article .content .article-link {
  margin: 15px 0;
}

.main-article .content .article-link .image-container {
  width: 322px;
}

.main-article .content .img-wrap {
  text-align: center;
}

.main-article .content .kw-in-art {
  background-color: #def9ff;
  margin-left: 60px;
  padding: 15px 10px;
  display: flex;
}

.main-article .content .kw-in-art .kw-logo {
  width: 50px;
}

.main-article .content .kw-in-art .kw-logo .icon {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  display: block;
}

.main-article .content .kw-in-art .kw-logo .icon svg path {
  fill: #1d223c;
}

.main-article .content .kw-in-art .kw-content {
  border-left: 1px solid #b2d0dd;
  padding-left: 20px;
}

.main-article .content .kw-in-art .kw-content strong {
  text-transform: uppercase;
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: 600;
  display: block;
}

.main-article .content .kw-in-art .kw-content a {
  letter-spacing: .5px;
  color: #fff;
  background-color: #7200fe;
  border-radius: 9px;
  height: 18px;
  margin: 12px 10px 0 0;
  padding: 0 9px;
  font-size: 11px;
  font-weight: 400;
  line-height: 18px;
  display: inline-block;
}

.main-article .content .kw-in-art .kw-content a:first-letter {
  text-transform: uppercase;
}

.main-article .article-comments {
  margin: 0;
  padding: 15px 0;
}

.main-article .related-articles {
  margin: 15px 0;
}

.main-article .related-articles .title-container .title {
  color: #6d94a6;
  text-transform: uppercase;
  padding-left: 0;
  font-size: 15px;
  font-weight: 700;
}

.main-article .shares-and-date {
  clear: both;
  padding: 15px 0;
  overflow: auto;
}

.main-article .shares-and-date .share-buttons {
  float: left;
}

.main-article .shares-and-date .share-buttons .button-twitter {
  margin-left: 20px;
}

.main-article .shares-and-date .date, .main-article .shares-and-date .commercial-label {
  float: right;
  color: #6d94a6;
  font-size: 15px;
  line-height: 26px;
}

.main-article p {
  margin: 0;
  padding: 15px 0;
  font-size: 17px;
}

.main-article .meta-container {
  background: #fff;
  width: 1050px;
  position: relative;
}

.main-article .meta-container:after, .main-article .meta-container:before {
  content: "";
  clear: both;
  display: table;
}

.main-article .meta-container .author-container {
  float: left;
  width: 100px;
}

.main-article .meta-container .author-container img.author-photo {
  border: 0;
  max-width: 80px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.main-article .meta-container .meta {
  float: left;
  margin: 20px 0 15px 100px;
}

.main-article .meta-container .meta .category-name {
  float: left;
  text-transform: uppercase;
  margin-right: 30px;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
}

.main-article .meta-container .meta .datetime {
  float: left;
  color: #b2d0dd;
  font-size: 18px;
  line-height: 20px;
}

.main-article .meta-container .meta .author-name-container {
  float: left;
  color: #b2d0dd;
  font-size: 18px;
  font-weight: 700;
}

.main-article .meta-container .meta .author-name-container a {
  color: #b2d0dd;
}

.main-article .meta-container .image-container:after, .main-article .meta-container .image-container:before {
  content: "";
  clear: both;
  display: table;
}

.main-article .article-gallery-container {
  background-color: #fff;
  padding: 20px;
}

.main-article .gallery h2 {
  color: #6d94a6;
  text-transform: uppercase;
  padding: 15px 0 5px;
  font-size: 15px;
  font-weight: normal;
}

.main-article .gallery .article-gallery-strip {
  margin-bottom: 20px;
}

.main-article .perex {
  margin: 0;
  padding: 0 0 0 60px;
  font-size: 17px;
  font-weight: 700;
  line-height: 22px;
}

.main-article .perex p {
  margin: 0;
  padding-left: 0;
  font-size: 17px;
  line-height: 22px;
}

.main-article figure {
  text-align: right;
  background-color: #fff;
  margin: 15px 0;
}

.main-article figure .image-in-article img {
  max-width: 614px;
  margin: 0 auto 10px;
  padding: 15px 0 0;
  display: block;
}

.main-article figure .image-in-article .description {
  color: #6d94a6;
  text-align: left;
  float: right;
  width: 100%;
  max-width: 614px;
  padding-top: 15px;
  display: block;
}

.main-article .related-articles-links {
  margin-top: 15px;
}

.main-article .authors-simple {
  padding: 15px 0;
}

.main-article.notlisted .bottom {
  display: none;
}

.main-article.listed ul.main {
  margin-bottom: 15px;
}

.main-article.listed ul.main > li {
  display: none;
  overflow: hidden;
}

.main-article.listed ul.main > li.active {
  display: block;
}

.main-article.listed .art-section-top {
  border-top: 1px solid #d3e6ef;
  padding-top: 20px;
}

.main-article.listed .art-section-top h2 {
  width: calc(100% - 100px);
}

.main-article.listed .sections-paging {
  position: relative;
}

.main-article.listed .sections-paging button {
  font-size: 15px;
  line-height: 15px;
}

.main-article.listed .sections-paging .start-listing {
  text-align: center;
  margin: 15px auto;
}

.main-article.listed .sections-paging .show-start {
  width: auto;
  padding: 13px 12px 12px;
  position: absolute;
  top: 1px;
  right: 0;
}

.main-article.listed .sections-paging .next, .main-article.listed .sections-paging .prev {
  width: 40px;
  height: 40px;
  padding: 0;
  position: absolute;
  top: 1px;
  right: 0;
}

.main-article.listed .sections-paging .next span, .main-article.listed .sections-paging .prev span {
  background-position: center;
  width: 15px;
  height: 25px;
  display: inline-block;
}

.main-article.listed .sections-paging .prev {
  right: 55px;
}

.main-article.keyword h2 {
  color: #6d94a6;
  font-size: 17px;
  font-weight: 700;
}

.main-article.keyword h3 {
  color: #6d94a6;
  font-size: 15px;
}

.main-article.keyword h4, .main-article.keyword .content p {
  font-size: 15px;
}

.main-article.keyword .content ul li {
  padding-left: 17px;
  font-size: 15px;
}

.main-article.keyword .content ul li:before {
  content: "•";
  color: #6d94a6;
  margin-left: -17px;
  font-size: 24px;
  font-weight: 600;
  line-height: 18px;
  position: absolute;
}

.main-article.keyword .content .kw-object {
  border-top: 1px solid #d3e6ef;
  border-bottom: 1px solid #d3e6ef;
  margin-left: 60px;
}

.main-article.keyword .content .victims {
  float: left;
  border-right: 1px solid #d3e6ef;
  width: 49%;
  margin: 20px 0;
}

.main-article.keyword .content .victims h2 {
  padding-top: 0;
  padding-left: 0;
}

.main-article.keyword .content .victims .victim {
  text-align: center;
  color: #6d94a6;
  width: 60px;
  font-size: 15px;
  font-weight: 600;
  display: inline-block;
}

.main-article.keyword .content .victims .victim .icon {
  background-position: bottom;
  width: 33px;
  height: 73px;
  margin: 20px auto 5px;
  display: block;
}

.main-article.keyword .content .victims .victim .dataimg-woman {
  width: 36px;
  height: 71px;
}

.main-article.keyword .content .victims .victim .dataimg-child {
  width: 26px;
  height: 48px;
}

.main-article.keyword .content .victims .victim.hidden {
  display: none;
}

.main-article.keyword .content .victims .victim-desc {
  color: #6d94a6;
  margin-top: 10px;
  font-size: 12px;
}

.main-article.keyword .content .victims .victim-desc > span {
  margin-right: 10px;
  display: inline-block;
}

.main-article.keyword .content .victims .victim-desc > span .bullet {
  background-color: #7200fe;
  border-radius: 5px;
  width: 10px;
  height: 10px;
  margin: 1px 5px 0 0;
  display: inline-block;
}

.main-article.keyword .content .affects {
  float: left;
  text-align: center;
  color: #6d94a6;
  width: 49%;
  margin: 20px 0;
  padding: 0 20px;
}

.main-article.keyword .content .affects h2 {
  text-align: left;
  padding-top: 0;
  padding-left: 0;
}

.main-article.keyword .content .affects .figurine {
  width: 50px;
  height: 102px;
  margin: 0 auto;
  position: relative;
}

.main-article.keyword .content .affects .figurine .dataimg-man {
  width: 46px;
  height: 100px;
}

.main-article.keyword .content .affects .figurine .dataimg-man svg path {
  fill: #b2d0dd;
}

.main-article.keyword .content .affects .figurine .dataimg-psyhika {
  width: 46px;
  height: 100px;
}

.main-article.keyword .content .affects .figurine .bullet {
  z-index: 1;
  background-color: #7200fe;
  border-radius: 5px;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 0;
  left: 0;
}

.main-article.keyword .content .affects .figurine .bullet.affect-1 {
  top: 4px;
  left: 18px;
}

.main-article.keyword .content .affects .figurine .bullet.affect-2 {
  top: 30px;
  left: 18px;
}

.main-article.keyword .content .affects .figurine .bullet.affect-3 {
  top: 22px;
}

.main-article.keyword .content .affects .figurine .bullet.affect-4 {
  top: 41px;
  left: 38px;
}

.main-article.keyword .content .affects .figurine .bullet.affect-5 {
  top: 53px;
  left: -1.5px;
}

.main-article.keyword .content .affects .figurine .bullet.affect-6 {
  top: 45px;
  left: 18px;
}

.main-article.keyword .content .affects .figurine .bullet.affect-7 {
  top: 75px;
  left: 11px;
}

.main-article.keyword .content .affects .figurine .bullet.affect-8 {
  top: 90px;
  left: 26px;
}

.main-article.keyword .content .affects .affects-text {
  font-weight: 600;
}

.main-article.keyword .content .affects .affects-text small {
  margin-top: 5px;
  font-size: 12px;
  font-weight: 400;
  display: block;
}

.main-article.keyword .content .incubation-parts {
  text-align: center;
  margin: 20px 0;
  display: table;
}

.main-article.keyword .content .incubation-parts .part {
  color: #6d94a6;
  vertical-align: top;
  border-right: 1px solid #d3e6ef;
  width: 205px;
  padding: 0 0 0 20px;
  font-weight: 600;
  display: table-cell;
}

.main-article.keyword .content .incubation-parts .part h2 {
  text-align: left;
  padding-top: 0;
  padding-left: 0;
}

.main-article.keyword .content .incubation-parts .part:first-of-type {
  padding-left: 0;
}

.main-article.keyword .content .incubation-parts .part:last-of-type {
  border-right: 0;
}

.main-article.keyword .content .incubation-parts .part .icon {
  background-position: center;
  width: 40px;
  height: 40px;
  margin: 25px auto 5px;
  display: block;
}

.main-article.keyword .content .incubation-parts .part small {
  margin-top: 5px;
  font-size: 12px;
  font-weight: 400;
  display: block;
}

.main-article.keyword .content .incubation-parts .part.vata {
  text-align: left;
  margin: 0;
  font-weight: 400;
}

.main-article.keyword .content .incubation-parts .part.vata .box {
  align-items: stretch;
  height: 131px;
  margin: 0;
  padding: 30px 15px 15px;
}

.main-article.keyword .content .incubation-parts .part.vata .box .box-footer {
  font-weight: 600;
}

.main-article.keyword .content .incubation-parts .part.vata .box .icon {
  width: 5px;
  margin: 0 0 0 3px;
  display: inline-block;
}

.main-article.keyword .content .incubation-parts .part.vata .box .icon path {
  fill: #7200fe;
}

.main-article.keyword .content .incubation-parts .part.vata.double {
  width: 410px;
}

.main-article.keyword .content .severity {
  text-align: center;
  margin: 20px 0;
}

.main-article.keyword .content .severity h2 {
  text-align: left;
  padding-top: 0;
  padding-left: 0;
}

.main-article.keyword .content .severity .severity-parts {
  color: #6d94a6;
  width: 100%;
  display: table;
}

.main-article.keyword .content .severity .severity-parts .part {
  color: #b2d0dd;
  width: 33.3%;
  padding: 15px 2px 0;
  font-weight: 600;
  display: table-cell;
}

.main-article.keyword .content .severity .severity-parts .part:first-of-type {
  padding-left: 0;
}

.main-article.keyword .content .severity .severity-parts .part:last-of-type {
  padding-right: 0;
}

.main-article.keyword .content .severity .severity-parts .part .severity-low {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "$color-grad-1", endColorstr= "$color-grad-2", GradientType= 1);
  background: linear-gradient(to right, #17d09a 0%, #b4d029 100%);
}

.main-article.keyword .content .severity .severity-parts .part .severity-medium {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "$color-grad-2", endColorstr= "$color-grad-3", GradientType= 1);
  background: linear-gradient(to right, #b4d029 0%, #ffa00e 100%);
}

.main-article.keyword .content .severity .severity-parts .part .severity-high {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "$color-grad-3", endColorstr= "$color-grad-4", GradientType= 1);
  background: linear-gradient(to right, #ffa00e 0%, #ff0172 100%);
}

.main-article.keyword .content .severity .severity-parts .part > div {
  opacity: .3;
  height: 6px;
  margin: 5px 0 0;
}

.main-article.keyword .content .severity .severity-parts .part.active {
  color: #6d94a6;
}

.main-article.keyword .content .severity .severity-parts .part.active > div {
  opacity: 1;
}

.main-article.keyword .perex {
  padding: 0;
}

.main-article.keyword .perex p {
  padding: 5px 0 15px;
  font-size: 15px;
}

.main-article.keyword .toc {
  text-align: right;
  margin: 5px 15px 15px;
  position: relative;
}

.main-article.keyword .toc a {
  color: #7200fe;
  padding: 5px 10px;
  font-size: 13px;
  font-weight: 700;
  text-decoration: underline;
  display: inline-block;
}

.main-article.keyword .toc a:hover {
  text-decoration: none;
}

.main-article.keyword .toc .icon {
  text-align: center;
  width: 15px;
  height: 15px;
  display: inline-block;
  position: absolute;
  top: 5px;
  right: -10px;
}

.main-article.keyword .article-keywords {
  margin: 17px 0 15px 60px;
}

.main-article.keyword .article-keywords .title {
  float: left;
  width: 17%;
  font-size: 17px;
  font-weight: 700;
}

.main-article.keyword .article-keywords .keywords {
  margin-left: 7.2em;
  font-size: 15px;
}

.main-article.keyword .related-articles .title-container .title {
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 600;
}

.main-article h1.title {
  font-size: 34px;
  font-weight: 700;
  line-height: 38px;
}

.main-article.keyword h1.title {
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
}

h1.title {
  font-size: 30px;
  font-weight: 700;
}

.search .title {
  margin-right: 10px;
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
  display: inline-block;
}

.article-gallery-strip {
  margin: 10px 0 15px;
}

.article-gallery-strip > ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.article-gallery-strip > ul > li {
  float: left;
  margin: 0 5px;
  padding: 0;
}

.article-gallery-strip > ul > li:before {
  display: none;
}

.article-gallery-strip > ul > li > a {
  display: block;
}

.article-gallery-strip > ul > li:first-child {
  margin: 0 7px 0 0;
}

.article-gallery-strip > ul > li.photos-left {
  color: #7200fe;
  margin: 0 0 0 5px;
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
  position: relative;
  top: 15px;
}

.article-gallery-strip > ul > li.photos-left .photos-count {
  text-align: center;
  font-size: 18px;
}

.article-gallery-strip > ul > li.photos-left .photos-count-desc {
  font-size: 15px;
  font-weight: 400;
}

.article-gallery-strip > ul > li.photos-left > a {
  color: #7200fe;
  text-decoration: none;
  display: block;
}

.article-gallery-strip > ul > li.photos-left > a:hover {
  text-decoration: none;
}

.article-gallery-strip > ul > li.photos-left > a:hover .photos-count-desc {
  text-decoration: underline;
}

.author-articles {
  border-top: 1px solid #b2d0dd;
  border-bottom: 1px solid #b2d0dd;
  margin: 15px 0;
  padding: 17px 8px;
  position: relative;
}

.author-articles.not-last {
  border-bottom: 0;
}

.author-articles:after {
  content: "";
  clear: both;
  height: 1px;
  margin-top: -1px;
  display: block;
  overflow: hidden;
}

.author-articles .author-image {
  text-align: center;
  float: left;
  width: 100px;
  height: 100px;
}

.author-articles .author-image > a {
  background-position: bottom;
  border: 1px solid #d3e6ef;
  border-radius: 0;
  width: 100px;
  height: 100px;
  margin: 0;
  display: block;
  overflow: hidden;
}

.author-articles .author-image > a img {
  width: 100%;
  height: auto;
}

.author-articles h3 {
  text-align: center;
  padding-top: 20px;
  padding-left: 0;
  font-size: 12px;
  font-weight: 700;
  position: absolute;
  bottom: 0;
  left: 130px;
}

.author-articles h3 a {
  color: #6d94a6;
  -webkit-text-decoration: no-decoration;
  text-decoration: no-decoration;
}

.author-articles .author-article-list {
  float: left;
  width: 470px;
}

.author-articles .author-article-list .category-name {
  vertical-align: middle;
  display: inline-block;
}

.author-articles .author-article-list .category-name a {
  color: #6d94a6;
  margin: 0 0 10px 30px;
  font-size: 13px;
  text-decoration: none;
  display: inline-block;
}

.author-articles .author-article-list .category-name a:hover {
  text-decoration: underline;
}

.article-header {
  margin: 30px;
}

.article-header .date-time {
  color: #6d94a6;
  float: left;
  width: 70px;
  font-size: 18px;
  line-height: 20px;
}

.article-header .title {
  color: #1d223c;
  float: left;
  width: 600px;
  margin: 0 0 0 20px;
  font-size: 34px;
  font-weight: 700;
}

.article-keywords {
  margin: 15px 0;
  position: relative;
}

.article-keywords > .title {
  color: #6d94a6;
  float: left;
  width: 60px;
  margin: 0;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  display: block;
}

.article-keywords .keywords {
  margin-left: 10px;
}

.article-keywords .keywords > a {
  color: #7200fe;
  line-height: 24px;
  display: inline-block;
}

.article-keywords .divider {
  color: #7200fe;
}

.gallery-container {
  background-color: #1e1e1e;
  height: 100%;
  margin-top: 60px;
}

.gallery-container .gallery-container {
  width: 1080px;
  height: 693px;
  margin: auto auto 60px;
}

.gallery-container .gallery-container .gallery-main-container div.image-description {
  padding: 10px;
}

.gallery-container .gallery-container .gallery {
  border: 1px solid #070707;
  height: 693px;
  position: relative;
}

.gallery-container .gallery-container .gallery .btn {
  background-color: rgba(100, 100, 100, .3);
  width: 90px;
  height: auto;
  top: 1px;
  bottom: 1px;
}

.gallery-container .gallery-container .gallery .images-container {
  height: 690px;
}

.gallery-container .gallery-container .image-description {
  display: block;
}

.gallery-container .gallery-advertisement {
  text-align: center;
  margin: 5px 0;
}

.gallery-main-container {
  background-color: #1e1e1e;
}

.gallery-main-container .gallery {
  height: 100%;
}

.gallery-main-container .gallery .btn {
  background-color: rgba(100, 100, 100, .3);
  width: 50px;
  height: 100%;
}

.gallery-main-container .gallery .btn .icon-arrow {
  width: 40px;
  height: 40px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 4%;
  right: 4%;
}

.gallery-main-container .gallery .next .image-count {
  opacity: 1;
  font-size: 15px;
  bottom: 15px;
}

.gallery-main-container .gallery .images-container {
  height: 397px;
}

.gallery-main-container .gallery .images-container .owl-item img {
  left: 0;
  right: 0;
}

.gallery-main-container .image-description {
  display: none;
}

@media screen and (max-width: 1900px) {
  .gallery-container .gallery-container {
    width: 100%;
    height: 350px;
    margin-bottom: 60px;
  }

  .gallery-container .image-description {
    padding: 15px;
  }

  .gallery-main-container .gallery .btn {
    width: 50px;
  }

  .gallery-main-container .gallery .next .image-count {
    font-size: .8em;
  }
}

.link-basic {
  color: #6d94a6;
}

.link-basic h3 > a {
  color: #6d94a6;
  font-size: 21px;
  font-weight: 400;
  line-height: 22px;
  text-decoration: underline;
  position: relative;
}

.link-basic h3 > a:hover {
  color: #6d94a6;
  text-decoration: none;
}

.link-basic h3 > a:visited {
  color: #7093a7;
}

.link-basic h3 > a:visited:hover {
  color: #6d94a6;
}

.link-basic h3 > a:after {
  content: " ";
  color: #6d94a6;
  background-image: url("/images/e15/dist/arrow-right.png");
  background-size: 5px 9px;
  width: 5px;
  height: 9px;
  font-size: 18px;
  font-weight: bold;
  position: absolute;
  top: 4px;
  right: -15px;
}

.link-basic h3 .dataimg-arrow-light-right {
  width: 15px;
  display: inline-block;
  position: relative;
  top: 3px;
  left: 0;
}

.link-basic h3 .dataimg-arrow-light-right g path {
  fill: #6d94a6 !important;
}

.article-link {
  border-bottom: 1px solid #d3e6ef;
  padding: 20px 0;
  overflow: auto;
}

.article-link .image-container {
  float: left;
  width: 322px;
  margin-right: 20px;
  padding-bottom: 0;
  display: block;
  position: relative;
}

.article-link .image-container .silhouette-image div {
  background-color: #d3e6ef;
  background-position: center;
  background-size: 80%;
  width: 322px;
  height: 215px;
}

.article-link .title-content h2 {
  float: left;
  max-width: 320px;
  margin: 10px 0 0;
  padding-top: 0;
  padding-left: 0;
  font-size: 30px;
  line-height: 36px;
}

.article-link .title-content h2 a {
  color: #1d223c;
  text-decoration: none;
}

.article-link .title-content h2 a:hover {
  color: #7200fe;
}

.top-line + .top-line .article-link {
  border-top: 0;
  padding-top: 5px;
}

.meta .category-name {
  vertical-align: middle;
  padding-top: 5px;
  display: inline-block;
}

.meta .category-name a {
  color: #6d94a6;
  text-transform: uppercase;
  font-size: 13px;
  text-decoration: none;
}

.meta .category-name a:hover {
  color: #6d94a6;
  text-decoration: underline;
}

.article-link-simple h2 {
  margin-top: 0;
}

.article-link-simple h2 a {
  color: #7200fe;
  font-size: 18px;
  text-decoration: underline;
}

.article-link-simple h2 a:hover {
  text-decoration: none;
}

.quote {
  float: none;
  text-align: center;
  border-top: 1px solid #b2d0dd;
  border-bottom: 1px solid #b2d0dd;
  margin: 15px 0;
  padding: 25px 0;
}

.quote blockquote {
  color: #1d223c;
  margin: 0;
  padding: 0;
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
}

.quote blockquote .quote-sign {
  font-size: 40px;
}

.quote blockquote .quote-sign.quote-sign-left {
  margin-left: -10px;
  margin-right: 5px;
}

form.wikisearch .form-inline-button .button-search {
  width: 40px;
}

form.wikisearch .form-inline-button .button-search .dataimg-search {
  width: 20px;
  height: 20px;
  padding: 10px;
  display: inline-block;
}

form.wikisearch .form-inline-button input[type="text"] {
  width: calc(100% - 40px);
}

form.wikisearch .form-inline-button input[type="text"]:hover:focus {
  border-right: 0;
}

.pagination {
  width: 100%;
  margin: 30px 0;
  display: table;
}

.pagination .prev-page, .pagination .next-page {
  text-align: center;
  vertical-align: top;
  width: 40px;
  display: table-cell;
}

.pagination .prev-page a, .pagination .next-page a {
  background-color: #7200fe;
  border-radius: 3px;
  width: 40px;
  height: 40px;
  transition: background-color .2s;
  display: inline-block;
}

.pagination .prev-page a span, .pagination .next-page a span {
  background-position: center;
  background-size: 45%;
  width: 30px;
  height: 40px;
  display: inline-block;
}

.pagination .prev-page a:hover, .pagination .next-page a:hover {
  background-color: #4300b2;
}

.pagination .page-nums {
  text-align: center;
  vertical-align: top;
  display: table-cell;
}

.pagination .page-nums a {
  color: #1d223c;
  padding: 0 15px;
  font-size: 21px;
  line-height: 40px;
  display: inline-block;
}

.pagination .page-nums a.aktpage {
  color: #7200fe;
}

.pagination .page-nums a:hover {
  text-decoration: underline;
}

.ajax-pagination .main-button {
  margin: 30px auto;
  font-size: 15px;
}

article + script + .first.top-line .article-link, article + script + script + .first.top-line .article-link {
  border-top: 0;
}

.abeceda .letters-bar {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.abeceda .letters-bar .letters-container {
  table-layout: fixed;
  width: 684px;
  margin: 0 -5px;
  display: table;
}

.abeceda .letters-bar .letters-container .letter {
  text-transform: uppercase;
  color: #7200fe;
  text-align: center;
  font-size: 21px;
  font-weight: 600;
  display: table-cell;
}

.abeceda .letters-bar .letters-container .letter a {
  color: #7200fe;
}

.abeceda .letters-bar .letters-container .letter.inactive {
  color: #6d94a6;
  font-weight: 600;
}

.abeceda .letters-bar .letters-container .letter.active {
  text-decoration: underline;
}

.abeceda .letters-list {
  position: relative;
}

.abeceda .letters-list .letter-list {
  border-bottom: 1px solid #d3e6ef;
  padding-bottom: 10px;
}

.abeceda .letters-list .letter-list:last-of-type {
  border-bottom: 0;
}

.abeceda .letters-list .letter-list .letter {
  text-transform: uppercase;
  color: #7200fe;
  margin-top: 20px;
  margin-bottom: 0;
  font-size: 30px;
  font-weight: 400;
}

.abeceda .letters-list .letter-list .list .item {
  float: left;
  color: #1d223c;
  width: 50%;
  font-size: 13px;
  line-height: 25px;
}

.abeceda .letters-list .letter-list .list .item a {
  color: #1d223c;
  text-decoration: none;
}

.abeceda .letters-list .letter-list .list .item a:hover {
  color: #7200fe;
  text-decoration: underline;
}

.abeceda .letters-list .letter-reset {
  position: absolute;
  top: 3px;
  right: 0;
}

.abeceda .letters-list .letter-reset a {
  color: #1d223c;
  font-size: 13px;
  font-weight: 600;
}

.abeceda .letters-list .letter-reset a em {
  vertical-align: middle;
  border: 1px solid #d3e6ef;
  border-radius: 9px;
  width: 18px;
  height: 19px;
  margin-right: 8px;
  font-weight: 600;
  display: inline-block;
  position: relative;
}

.abeceda .letters-list .letter-reset a em .icon {
  width: 18px;
  height: 12px;
  position: absolute;
  top: 2px;
  right: -4px;
}

.list-article-topbox {
  margin: 20px 0;
  display: table;
}

.list-article-topbox a:hover {
  color: #7200fe;
}

.list-article-topbox .article-top {
  vertical-align: top;
  width: 530px;
  display: table-cell;
}

.list-article-topbox .article-top > div {
  border-right: 1px solid #d3e6ef;
  margin: 0 15px 0 0;
  padding: 0 15px 0 0;
}

.list-article-topbox .article-side {
  vertical-align: top;
  max-width: 148px;
  display: table-cell;
}

.list-article-topbox .article-side .list-article {
  border-top: 1px solid #d3e6ef;
  padding-top: 20px;
}

.list-article-topbox .article-side .list-article:first-of-type {
  border-top: 0;
  margin-top: 15px;
}

.article-big-vertical h2 {
  max-width: 498px;
  margin-top: 15px;
  line-height: 38px;
}

.article-big-vertical h2 a {
  color: #1d223c;
  font-size: 34px;
  font-weight: 700;
}

.article-big-vertical .image-container {
  width: 498px;
  padding-bottom: 0;
  display: block;
  position: relative;
}

.article-big-vertical .image-container .silhouette-image div {
  background-color: #d3e6ef;
  background-position: center;
  background-size: 80%;
  width: 498px;
  height: 288px;
}

.article-big-vertical .meta {
  margin-bottom: 15px;
}

.article-big-vertical .meta .category-name {
  padding-top: 0;
}

.article-small-vertical {
  float: left;
  width: 146px;
}

.article-small-vertical h2 {
  margin-top: 10px;
  margin-bottom: 20px;
  line-height: 18px;
}

.article-small-vertical h2 a {
  color: #1d223c;
  font-size: 15px;
  font-weight: 700;
}

.article-small-vertical .image-container {
  width: 146px;
  padding-bottom: 0;
  display: block;
  position: relative;
}

.article-small-vertical .image-container .silhouette-image div {
  background-color: #d3e6ef;
  background-position: center;
  background-size: 80%;
  width: 146px;
  height: 84px;
}

.list-article-row .article-small-vertical {
  margin: 15px;
}

.list-article-row .article-small-vertical:first-child {
  margin-left: 0;
}

.list-article-row .article-small-vertical:nth-child(6n+6) {
  margin-right: 0;
}

.tag-cloud .tag-word {
  color: #fff;
  white-space: nowrap;
  vertical-align: middle;
  background-color: #b2d0dd;
  border-radius: 9px;
  height: 18px;
  margin: 5px 10px 5px 0;
  padding: 0 10px;
  font-size: 12px;
  line-height: 18px;
  transition: all .3s ease-in-out;
  display: inline-block;
}

.tag-cloud .tag-word:first-letter {
  text-transform: uppercase;
}

.tag-cloud .tag-word.color-1 {
  background-color: #6d94a6;
}

.tag-cloud .tag-word.color-2 {
  background-color: #93bacc;
}

.tag-cloud .tag-word.color-3 {
  background-color: #add4e6;
}

.tag-cloud .tag-word:hover {
  background-color: #7093a7;
  text-decoration: none;
}

.box {
  color: #6d94a6;
  background-color: #def9ff;
  margin: 25px 0;
  padding: 35px 20px 20px;
  font-size: 15px;
  line-height: 18px;
}

.box:after {
  content: "";
  clear: both;
  width: 100%;
  height: 0;
  display: table;
}

.box .box-footer {
  text-align: right;
  color: #7200fe;
  padding-top: 5px;
  font-size: 13px;
  font-weight: 700;
}

.box .box-footer a {
  color: #7200fe;
  margin-right: -3px;
  text-decoration: underline;
}

.box .box-footer a .icon {
  width: 5px;
  margin-left: 3px;
  text-decoration: none;
  display: inline-block;
}

.box .box-footer a .icon path {
  fill: #7200fe;
}

.box .box-footer a:hover {
  text-decoration: none;
}

.ajax-pagination + script + .edition-promo {
  margin-top: 60px;
}

.edition-promo {
  float: left;
  border: 1px solid #d3e6ef;
  width: 100%;
  margin: 20px 0;
  padding: 10px;
}

.edition-promo img {
  float: left;
  border: 0;
  width: 145px;
  height: auto;
}

.edition-promo .edition-body {
  margin-left: 205px;
}

.edition-promo .edition-body h2 {
  letter-spacing: 1px;
  margin: 20px 0 5px;
  font-size: 26px;
}

.edition-promo .edition-body p {
  height: 80px;
  font-size: 17px;
  line-height: 22px;
}

.edition-promo .edition-body .main-button {
  font-size: 15px;
  display: inline-block;
}

.edition-promo .edition-body .edition-info {
  color: #7200fe;
  margin-left: 30px;
  font-size: 13px;
  line-height: 22px;
  text-decoration: underline;
  display: inline-block;
}

.edition-promo .edition-body .edition-info .icon {
  width: 6px;
  height: 13px;
  margin-left: 6px;
  display: inline-block;
}

.edition-promo .edition-body .edition-info .icon path {
  fill: #7200fe;
}

.edition-promo .edition-body .edition-info:hover {
  text-decoration: none;
}

.edition-promo:after {
  content: "";
  clear: both;
  height: 0;
  display: table;
}

article.list-article-line {
  width: 100%;
  max-width: 470px;
  margin: 0 20px;
}

article.list-article-line .title-container {
  width: 100%;
}

article.list-article-line .title-container h2 {
  width: 100%;
  margin: 0 0 8px;
  font-size: 15px;
}

article.list-article-line .title-container h2 a {
  color: #1d223c;
  display: block;
}

article.list-article-line .title-container h2 a strong {
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
  max-width: calc(100% - 20px);
  height: 18px;
  line-height: 18px;
  display: inline-block;
  overflow: hidden;
}

article.list-article-line .title-container h2 a .icon {
  vertical-align: middle;
  width: 7px;
  height: 15px;
  margin-left: 2px;
  display: inline-block;
}

article.list-article-line .title-container h2 a .icon path {
  fill: #1d223c;
  transition: all .3s ease-in-out;
}

article.list-article-line .title-container h2 a:hover {
  color: #6d94a6;
  text-decoration: none;
}

article.list-article-line .title-container h2 a:hover .icon {
  text-decoration: none;
}

article.list-article-line .title-container h2 a:hover .icon path {
  fill: #6d94a6;
}

.onlinetalk {
  position: relative;
}

.question-answer {
  border-bottom: 1px solid #aaa;
  margin-bottom: 20px;
  padding: 5px;
  font-size: 16px;
  line-height: 1.7;
  transition: all .2s;
}

.question-answer .text-container {
  margin-bottom: 20px;
  padding: 5px;
  font-size: 16px;
  line-height: 1.7;
  transition: all .2s;
}

.question-answer .text-container .text-container {
  margin-bottom: 20px;
}

.question-answer .text-container .text-container:after, .question-answer .text-container .text-container:before {
  content: "";
  clear: both;
  display: table;
}

.question-answer .text-container .left {
  float: left;
  width: 15%;
}

.question-answer .text-container .left img {
  border: 1px solid #aaa;
  border-radius: 50%;
  max-width: 80%;
  height: auto;
  display: block;
  position: relative;
  top: 2px;
}

.question-answer .text-container .left .image-wrapper {
  border: 1px solid #aaa;
  border-radius: 50%;
  width: 40%;
  display: block;
}

.question-answer .text-container .right {
  float: right;
  width: 85%;
}

.question-answer .text-container .questioner {
  color: #000;
  margin: 0;
  font-size: 11px;
}

.question-answer .text-container.new {
  background: #aaa;
  border-radius: 5px;
}

.question-answer .chat-header {
  border-top: 3px solid #1d223c;
  padding-top: 5px;
}

.question-answer .chat-header .onlinetalk-title {
  color: #1d223c;
}

.question-answer .chat-header .onlinetalk-title.title-with-status {
  float: left;
  width: 85%;
}

.question-answer .chat-status {
  float: right;
  text-transform: uppercase;
  text-align: center;
  width: 15%;
  font-size: 18px;
  font-weight: bold;
}

.question-answer .chat-status .dataimg-online, .question-answer .chat-status .dataimg-online-live {
  width: 25px;
  margin: 0 auto;
}

.question-answer .chat-status .dataimg-online g, .question-answer .chat-status .dataimg-online-live g {
  fill: #1d223c;
}

.chat-refresh {
  color: #7200fe;
  text-align: center;
  font-weight: bold;
  text-decoration: underline;
  display: block;
}

.chat-header {
  border-top: 0;
  border-bottom: 1px solid #d3e6ef;
  padding-bottom: 20px;
}

.chat-header .chat-refresh {
  float: right;
  margin-top: -50px;
  font-size: 13px;
}

.chat-header .chat-refresh.new-state {
  float: none;
  margin: 20px 0 0;
}

.chat-header > .clear {
  border-top: 1px solid #d3e6ef;
}

.chat-header > a.button-main {
  margin: 30px auto;
  display: none;
}

.chat-header h2.onlinetalk-title {
  color: #6d94a6;
  padding-left: 0;
  font-size: 21px;
  font-weight: 600;
}

.chat-header h2.onlinetalk-title.title-with-status {
  padding-top: 0;
}

.chat-header a.button-main {
  margin-bottom: 20px;
  font-size: 17px;
}

.chat-header .chat-status {
  color: #6d94a6;
  width: auto;
  font-size: 12px;
  font-weight: 400;
}

.chat-header .chat-status .dataimg-online g, .chat-header .chat-status .dataimg-online path, .chat-header .chat-status .dataimg-online-live g, .chat-header .chat-status .dataimg-online-live path {
  fill: #6d94a6;
}

.question-answer {
  border-color: #d3e6ef;
  font-size: 17px;
}

.question-answer .question .questioner {
  color: #6d94a6;
  font-size: 17px;
  font-weight: bold;
}

.question-answer .question .left {
  float: none;
  width: 0;
}

.question-answer .question .right {
  float: none;
  width: 100%;
}

.question-answer .question .text-container {
  margin-bottom: 5px;
}

.question-answer .answer .left img {
  float: right;
  border: 1px solid #d3e6ef;
  border-radius: 0;
  margin-top: -10px;
  margin-right: 13px;
}

form.chat-form .main-button {
  margin: 0 auto;
  font-size: 17px;
}

form.chat-form .form-field .counter {
  color: #1d223c;
}

form.chat-form .form-field label {
  font-weight: bold;
}

form.chat-form .form-field textarea {
  min-height: 175px;
}

form.chat-form .form-field input[type="text"] {
  max-width: 240px;
}

form.chat-form .error-message {
  clear: left;
}

form.chat-form .success-message {
  float: none;
  text-align: center;
  margin: 0 auto;
}

@font-face {
  font-family: opensans-regular;
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.eot");
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.woff2") format("woff2"), url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.woff") format("woff"), url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: opensans-bold;
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-bold-webfont.eot");
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-bold-webfont.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/open-sans/opensans-bold-webfont.woff2") format("woff2"), url("//img2.cncenter.cz/fonts/open-sans/opensans-bold-webfont.woff") format("woff"), url("//img2.cncenter.cz/fonts/open-sans/opensans-bold-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.cookie-bar {
  text-align: center;
  color: #fff;
  z-index: 200000;
  background-color: rgba(50, 50, 50, .95);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 0;
  font-family: opensans-regular, arial, sans-serif;
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.cookie-bar a, .cookie-bar a:hover {
  color: inherit;
}

.cookie-bar__title {
  font-family: opensans-bold, arial, sans-serif;
  font-size: 26px;
  line-height: 36px;
}

.cookie-bar__title br {
  display: none;
}

.cookie-bar__text {
  padding: 5px 0 10px;
  font-size: 12px;
  line-height: 16px;
}

.cookie-bar__text br {
  display: inline;
}

.cookie-bar__enable-btn {
  text-transform: uppercase;
  cursor: pointer;
  background-color: red;
  width: 140px;
  height: 35px;
  margin: 0 15px 10px auto;
  font-size: 14px;
  line-height: 35px;
  text-decoration: none;
  display: inline-block;
}

.cookie-bar__more-info {
  margin-bottom: 0;
  font-size: 11px;
  line-height: 15px;
  text-decoration: underline;
  display: inline-block;
}

.cookie-bar__more-info:hover {
  text-decoration: none;
}

.cookie-bar__close-btn {
  cursor: pointer;
  width: 14px;
  height: 14px;
  display: inline-block;
  position: absolute;
  top: 10px;
  right: 10px;
  overflow: hidden;
}

.cookie-bar__close-btn:before, .cookie-bar__close-btn:after {
  content: "";
  background: #fff;
  width: 100%;
  height: 2px;
  margin-top: -1px;
  position: absolute;
  top: 50%;
  left: 0;
}

.cookie-bar__close-btn:before {
  transform: rotate(45deg);
}

.cookie-bar__close-btn:after {
  transform: rotate(-45deg);
}

.cookie-bar__close-btn--thick:before, .cookie-bar__close-btn--thick:after {
  height: 3px;
  margin-top: -2px;
}

.box-admin {
  float: left;
  width: auto;
  height: auto;
}

.box-admin__title {
  color: #6d94a6;
  text-transform: uppercase;
  margin: 0 0 10px;
  font-size: 15px;
}

.box-admin__article {
  float: left;
  border-bottom: 1px solid #deecf3;
  width: 100%;
  height: auto;
  margin: 0 0 16.5px;
  padding: 0 0 6.5px;
}

.box-admin__article--horizontal {
  border-bottom: none;
  width: 192px;
  margin: 0 16px 15px 0;
  padding: 0;
}

.box-admin__article--horizontal:nth-of-type(5n) {
  margin: 0;
}

.box-admin__img {
  float: left;
  width: 100%;
  height: auto;
  position: relative;
}

.box-admin__img img {
  width: 100%;
}

.box-admin__img span {
  color: #fff;
  text-transform: uppercase;
  background-color: rgba(0, 0, 0, .6);
  width: 100%;
  height: 24px;
  padding: 0 0 0 8px;
  font-size: 12px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.box-admin__img span a {
  color: #fff;
  line-height: 24px;
}

.box-admin__perex {
  float: left;
  margin: 8px 0 0;
  font-size: 16px;
  font-weight: bold;
}

.box-admin__perex a {
  color: #000;
}

.loading-placeholder {
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
}

.loading-placeholder img {
  opacity: 0;
  width: 100%;
  transition: opacity 1s;
}

.loading-placeholder.item-loaded {
  padding-bottom: 0 !important;
}

.loading-placeholder.item-loaded img {
  opacity: 1;
  height: auto;
}

.loading-placeholder:not(.item-loaded) {
  background-color: #e8e8e8;
  height: auto;
  position: relative;
}

.loading-placeholder:not(.item-loaded):after {
  content: " ";
  background-color: #e8e8e8;
  background-image: url("https://img2.cncenter.cz/images/default/lazy-load/lazyload_placeholder.gif");
  background-repeat: repeat-y;
  background-size: 100% 1px;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.loading-placeholder:not(.item-loaded) img {
  text-indent: -9999px;
  color: rgba(0, 0, 0, 0);
  position: absolute;
}

@keyframes gradient-animation {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

.box-admin__img img.lazyload.loaded {
  height: auto;
}

.banner-print * {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
}

.banner-print > a {
  float: left;
  background: url("/images/moje-zdravi/heart.png?v=2") 90% 100% no-repeat;
  border: 1px solid #d3e6ef;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 104px;
  margin: 25px 0 20px;
  display: flex;
}

.banner-print > a:hover {
  text-decoration: none;
}

.banner-print img {
  margin-left: 20px;
  display: block;
  box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
}

.banner-print span:nth-child(2) {
  text-align: center;
  color: #16212d;
  text-transform: uppercase;
  align-items: center;
  width: 255px;
  height: 77px;
  font-family: Open Sans, sans-serif;
  font-size: 21px;
  font-weight: bold;
  line-height: 1.06;
  display: flex;
  position: relative;
}

.banner-print span:nth-child(3) {
  text-shadow: 0 1px 6px #f4f5f5;
  text-align: center;
  color: #16212d;
  align-items: center;
  width: 235px;
  height: 28px;
  font-family: Open Sans, sans-serif;
  font-size: 20px;
  font-style: italic;
  font-weight: 600;
  display: flex;
  position: relative;
}

.banner-print span:nth-child(3):before {
  content: " ";
  background: url("/images/moje-zdravi/dist/svg/plus.svg") no-repeat;
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  top: 50%;
  left: -52px;
  transform: translateY(-50%);
}

.banner-print span:last-child {
  text-align: center;
  color: #fff;
  background-color: #7200fe;
  width: 185px;
  height: 40px;
  margin: 0 20px 0 0;
  padding: 10px 24px 11px;
  font-family: Open Sans, sans-serif;
  font-size: 13px;
  font-weight: bold;
}

